import React, {Component} from 'react';
import axios from "axios";
import {ConfigProvider, Layout, message, Space} from 'antd';
import {axiosApi} from "./axios-api";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {createGlobalStyle} from 'styled-components';
import initSentry from "./sentry/sentry";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AdminManagementPage from "./components/admin/AdminManagementPage/AdminManagementPage";
import Login from "./components/website/auth/Login.jsx";
import Register from "./components/website/auth/Register.jsx";
import NotFound from './components/website/errors/NotFound';
import NodesApp from "./components/nodeInterface/NodesApp";
import GraphicEditor from './components/graphicEditor/GraphicEditorApp'
import MappingPage from './components/mappingPage/MappingPage';
import {COLORS} from "./constants";
import {
  addLocalStorage,
  convertNoticeCode,
  filterOrganizationsByRole,
  getUserLocalStorage,
  handleOneLineError,
  removeDuplicates,
  saveUserInfo,
} from './helpers';
import ProjectsPage from "./components/Project/ProjectsPage/ProjectsPage";
import AppSidebar from "./components/AppSidebar/AppSidebar";
import ProtectedRoute from "./components/RouteHelpers/ProtectedRoute/ProtectedRoute";
import SuccessPayment from "./components/RouteHelpers/SuccessPayment/SuccessPayment";
import RegisterByInvitation from "./components/website/auth/RegisterByInvitation";
import RegistrationConfirmation
  from './components/website/auth/RegistrationConfirmation';
import { SocketInitializer } from 'contexts/SocketContext';
import RequestResetPassword from './components/website/auth/RequestResetPassword';
import ResetPassword from './components/website/auth/ResetPassword';

initSentry();

const {Content} = Layout;

export const headerHeight = 60;
export const contentHeight = window.innerHeight - headerHeight;

export default class App extends Component {

    deleteLocalStorageValue = key => {
        window.localStorage.removeItem(key);
    };

    setUserAuth = data => {
        const userTokens = {
            refresh: data.refresh,
            access: data.access,
        }

        addLocalStorage(userTokens)
        this.setState({
            user: {user: data.user}
        });
    };


    getInvitationCode = async (code) => {
        try {
            const {data} = await axiosApi.get(`/v2/accounts/registration/organization/?code=${code}`);
            if (Object.keys(data).length === 0) return;

            return data;
        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.response.data.detail,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    }

    finishRegistrationForInvitedUser = async (requestBody, setError, history) => {
        try {
            const {data} = await axiosApi.post('/v2/accounts/registration/invitation/', requestBody);
            if (!data) return;
            message.success({
                content: 'Account has been created successfully, please log in',
                duration: 3,
            })
            setError({});
            history.push('/login');

        } catch (error) {
            if (error.isAxiosError) {
                const {data} = error.response;
                const errorFields = {};

                if (!data.username && !data.invitation_code && !data.password) {
                    message.warning({
                        content: 'Registration finished with error! Try again in a while.',
                        duration: 3,
                    });
                    return;
                }

                if (data.invitation_code) {
                    message.warning({
                        content: data.invitation_code[0],
                        duration: 3,
                    });
                }

                if (data.username) {
                    errorFields.username = data.username[0];
                }

                if (data.password) {
                    errorFields.password =  'Password has to be at least 8 characters long and includes both letters and numbers';
                }

                setError(errorFields)
            } else {
                throw new Error(error);
            }
        }
    }

  confirmRegistration = async (code, setLoading) => {
    try {
      setLoading(true);
      const {data} = await axiosApi.patch(`/v2/accounts/registration-confirmation/?code=${code}`);
      return data;
    } catch (error) {
      if (error.isAxiosError) {
        message.warning({
          content: 'User not found or the confirmation link is expired',
          duration: 5,
        });
      } else {
        throw new Error(error);
      }
    } finally {
      setLoading(false);
    }
  };

    logIn = async (requestData, history) => {
        try {
            const {data} = await axiosApi.post('/v2/accounts/login/', requestData,);

            if (data) {
                saveUserInfo(data);

                await this.checkUserAuthAndOrganizations();
                const {pathname} = window.location;

                if (pathname === '/login') {
                    history.push('/');
                }

                message.success({
                    content: 'Success login!',
                    duration: 3,
                });

            }
        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.response.data.detail,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    logOut = async () => {
      try {
          const { status } = await axiosApi.post('/v2/accounts/logout/');

          if(status === 200) {
              this.deleteLocalStorageValue('tokens');
              this.deleteLocalStorageValue('userInfo');
              this.setState({
                user: undefined,
                organizations: [],
                organizationsByProjects: [],
                organizationsWithFilteredProjects: [],
                selectedOrganizationsList: [],
                selectedProjects: [],
                paymentsData: [],
                billingPlans: [],
                projects: []
              });

              message.success('You have been successfully logged out');
              return
          }

          throw new Error
      } catch (error) {
          message.error('Something went wrong! Please try again in a while');

          throw new Error
      }
    };

    register = async (requestBody, setError, history) => {
        try {
            const {data} = await axiosApi.post('v2/accounts/registration/', requestBody);
            saveUserInfo(data);
            await this.checkUserAuthAndOrganizations();

            message.success({
                content: "Success!",
                duration: 3
            });

            return true;
        } catch (error) {
            if (error.isAxiosError) {
                const {data} = error.response;
                const errorFields = {};
                Object.keys(data).forEach(key => {
                    if (key === 'organization' && data[key].name) {
                        errorFields.organizationName = data[key].name[0];
                    } else {
                        errorFields[key] = data[key][0];
                    }
                });
                setError(errorFields);

                if (error.response.status === 500) {
                    message.warning('Registration finished with error! Try again in a while.');
                }
            } else {
                throw new Error(error);
            }
        }
    };

  resetPassword = async (email, setError, setLoading) => {
    try {
      setLoading(true);
      await axiosApi.post('v2/accounts/password-request/', email);
      setError('');
      message.success({
        content: 'Email successfully sent!',
        duration: 3,
      });
      return true;
    } catch (error) {
      if (error.isAxiosError) {
        if (error.response.status === 500) {
          message.warning(
            'Password reset finished with error! Try again in a while.',
          );
          return;
        }
        handleOneLineError(error, setError);
      } else {
        throw new Error(error);
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  setNewPassword = async (code, requestBody, setError, setLoading) => {
    try {
      setLoading(true);
      await axiosApi.patch(
        `v2/accounts/new-password/?code=${code}`,
        requestBody,
      );
      setError('');
      message.success({
        content: 'Password successfully changed!',
        duration: 3,
      });
      return true;
    } catch (error) {
      if (error.isAxiosError) {
        if (
          error.response.status === 404 &&
          error.response?.data.detail === 'Not found.'
        ) {
          setError('The reset password link seems to be broken or expired');
          return;
        }
        if (error.response.status === 500) {
          message.warning(
            'Password change finished with error! Try again in a while.',
          );
          return;
        }
        handleOneLineError(error, setError);
      } else {
        throw new Error(error);
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

    getUserInfo = async () => {
        try {
            const response = await axiosApi.get('/current_user/');
            const user = response.data;

            if (user.id) {
                const userData = await axiosApi.get(`/v2/accounts/users/${user.id}/`);
                const userInfo = userData.data;

                if (!userInfo) return;

                const isAllNotAdmin = userInfo.roles_and_perms.every(access => access.role !== 1);

                const usersInfoLocal = {
                    roles: userInfo.roles_and_perms,
                    isSuperUser: userInfo.is_superuser
                };

                await addLocalStorage('userInfo', usersInfoLocal);

                const userState = {
                    id: userInfo.id,
                    username: userInfo.username,
                    isSuperUser: userInfo.is_superuser,
                    roles: userInfo.roles_and_perms,
                    isAllNotAdmin: userInfo.is_superuser ? false : isAllNotAdmin,
                };

                return {user: userState};
            }
        } catch (error) {
            console.error(error)
        }
    }

    checkUserAuthAndOrganizations = async () => {
        try {
            const {csrf, refresh} = getUserLocalStorage();

            if (csrf && refresh) {
                const {user} = await this.getUserInfo();
                this.setState({user})
            }

        } catch (e) {
            this.setState({user: null});
        }
    };

    fetchProject = async (project_id) => {
        try {
            const {data} = await axiosApi.get(`/projects/${project_id}/`);
            this.setState({
              selectedProjectId: data?.id,
            });

            return data;

        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        }
    };

    fetchFeed = async (project_id, feed_id) => {
        try {
            const {data} = await axiosApi.get(`/projects/${project_id}/feeds/${feed_id}/`);
            return data;

        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        }
    };

    fetchRule = async (project_id, rule_id) => {
        try {
            const {data} = await axiosApi.get(`/projects/${project_id}/rules/${rule_id}/`);
            return data;

        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        }
    };

    fetchMapping = async (project_id, mapping_id) => {
        try {
            const {data} = await axiosApi.get(`/projects/${project_id}/mappings/${mapping_id}/`);
            return data;
        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        }
    };

    fetchFeedTypes = async () => {
        try {
            const {data} = await axios.get(`/data/feed_types.json`);
            return data;
        } catch (error) {
            throw new Error(`[fetchFeedTypes] Error.\n${error.message}`);
        }
    }


    fetchDesign = async (project_id, design_id) => {
        try {
            const {data} = await axiosApi.get(`/projects/${project_id}/designs/${design_id}/`);
            return data;

        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        }
    };


    fetchDesigns = async (project_id) => {
        try {
            const {data} = await axiosApi.get(`/projects/${project_id}/designs/`);
            return data;

        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        }
    };

    deleteDesign = async (project_id, id) => {
        try {
            await axiosApi.delete(`/projects/${project_id}/designs/${id}`);
            return true;
        } catch (error) {
            message.error(error.message);
            return false;
        }
    };

    getFonts = async (organizationId) => {
        try {
            const {data} = await axiosApi.get(`v2/projects/fonts/organization/${organizationId}`);

            const mergedFonts = [
                ...this.state.designFonts.map(font => ({
                    ...font,
                    isCustom: true,
                })),
                ...data.uploaded_fonts.map(font => ({
                    ...font,
                    isCustom: true,
                })),
                ...data.default_fonts.map(font => ({
                    ...font,
                    id: Math.floor(Math.random() * 10000),
                    isCustom: false,
                }))
            ];

           const filteredFonts = removeDuplicates(mergedFonts);

            this.setState({
                fonts: filteredFonts,
            });

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    setDesignFont = (designFonts) => {
        this.setState({
            designFonts: designFonts
        });
    };

    uploadFont = async (file, organizationId) => {
        try {

            if (this.state.fonts.some((font) => font.name === file.name)) {
                message.warning('This font is already uploaded');
                return;
            }

            const formData = new FormData();
            formData.append('font', file, file.name);
            formData.append('name', file.name);
            formData.append('organization', organizationId);


            await axiosApi.post('/v2/projects/fonts/', formData, {
                headers: {'Content-Type': 'multipart/form-data',},
            });
            await this.getFonts(organizationId);

            return this.state.fonts.find((font) => font.name === file.name);
        } catch (error) {
            message.error(error.message);
        }
    };


    deleteFont = async (id) => {
        try {
            await axiosApi.delete(`/fonts/${id}`);
            const {user} = this.state;
            user.fonts = user.fonts.filter(fonts => fonts.id !== id);
            this.setState({
                user: {...user},
            });

        } catch (error) {
            message.error(error.message);
        }
    };

    deleteFile = async (id) => {
        try {
            await axiosApi.delete(`/files/${id}`);
        } catch (error) {
            message.error(error.message);
        }
    };

    getOrganizations = async () => {
        try {
            this.setState({
                loadingOrganizations: true,
                organizations: [],
                organizationsByProjects: [],
                paymentsData: [],
            });
            const {data} = await axiosApi.get('v2/accounts/organizations/roles/');

            const paymentsData = await this.getPaymentsData();
            let filteredOrganizations = filterOrganizationsByRole(data, this.state.user.roles);

            if (paymentsData) {
                filteredOrganizations = filteredOrganizations.map(item => {
                    const paymentInfo = paymentsData.find(data => data.id === item.id);
                    return {
                        ...item,
                        ...paymentInfo,
                    };
                });
            }

            const isUnpaidOrganization = filteredOrganizations.some((organization) => convertNoticeCode(organization.notice_code));

            if (isUnpaidOrganization) {
                const defaultBillingPlans = await this.getBillingPlan();
                this.setState({
                    billingPlans: defaultBillingPlans,
                });
            }
            const organizations = filteredOrganizations.map((organization) => ({
                id: organization.id,
                key: organization.id,
                name: organization.name,
                projects: organization.projects,
            }));
            this.setState({
                organizations: organizations,
                organizationsByProjects: filteredOrganizations,
                paymentsData: paymentsData || [],
            });
        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        } finally {
            this.setState({
                loadingOrganizations: false
            });
        }
    };

    getOneOrganization = async (organizationId) => {
        try {
            const {data} = await axiosApi.get(`v2/accounts/organizations/roles/?organization=${organizationId}`);
            return data;

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    }

    outputParser = (organization) => {
        return organization.projects_filtered_by_id.map((project) => {
            if (project.outputs.length) {
                project.outputs = project.outputs.map((log) => ({
                    ...log,
                    validation_log_json: log.validation_log_json ? JSON.parse(log.validation_log_json) : {},
                }));
            }
            return project;
        });
    }
    getOrganizationsWithFilteredProjects = async (organizationId) => {
        try {
            this.setState({
                loadingOrganizations: true,
                selectedProjects: [],
                organizationsWithFilteredProjects: []
            });
            const {data} = await axiosApi.get(`v2/accounts/organizations/projects/${organizationId ? `?id__in=${organizationId}` : ''}`);

            const filteredOrganizationsWithProjects = await data.map((organization) => {
                if (organization.projects_filtered_by_id.length) {
                    this.outputParser(organization);
                }
                const role = this.state.user.roles.find(role => role.organization === organization.id);
                return {
                    ...organization,
                    generation_allowed: this.state.user.isSuperUser ? true : organization.generation_allowed,
                    role: this.state.user.isSuperUser ? 1 : (role ? role.role : null),
                };
            });

						const filteredOrganizations = filteredOrganizationsWithProjects.map((organization) => ({
							id: organization.id,
							key: organization.id,
							name: organization.name,
							projects: organization.projects_filtered_by_id.map((project) => ({id: project.id, name: project.name})),
						}));

            this.setState({
                organizationsWithFilteredProjects: filteredOrganizationsWithProjects,
                organizations: filteredOrganizations,
            });

            return data;
        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        } finally {
            this.setState({
                loadingOrganizations: false
            });
        }
    }

    getOneProjectByOrganizationAndUpdateState = async (organizationId, projectId) => {
        try {
            this.setState({
                loadingOneProject: true,
            });

            const {data} = await axiosApi.get(`v2/accounts/organizations/projects/?id__in=${organizationId}&projects_in=${projectId}`);

            if (!data) return;

            const parsingProject = await data.map((organization) => {
                if (organization.projects_filtered_by_id.length) {
                    this.outputParser(organization);
                }
                return {...organization}
            });

            const newProject = parsingProject[0];

            const updateStatesOfProjects = (changingList ) => {
              return  changingList.forEach((organization) => {
                    if (organization.id === newProject.id) {
                        organization.projects_filtered_by_id.forEach((project, index) => {
                            if (project.id === newProject.projects_filtered_by_id[0].id) {
                                organization.projects_filtered_by_id[index] = {...newProject.projects_filtered_by_id[0]};
                            }
                            return {...project}
                        });
                    }
                })
            }

            const organizationsWithFilteredProjects = [...this.state.organizationsWithFilteredProjects];
            const selectedProjects = [...this.state.selectedProjects];

            updateStatesOfProjects(selectedProjects);
            updateStatesOfProjects(organizationsWithFilteredProjects);

            this.setState({
                selectedProjects,
                organizationsWithFilteredProjects
            });

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }

        } finally {
            this.setState({
                loadingOneProject: false,
            });
        }
    }

    clearOrganizationsWithFilteredProjects = () => {
        this.setState({
            organizationsWithFilteredProjects: []
        })
    }

    selectOrganization = (organization) => {
        this.setState({
            selectedOrganization: organization
        })
    };

    toggleIsManagementSelected = (selectedOrganization) => {
        const selectedOrganizationsList = [...this.state.selectedOrganizationsList];
        const updatedOrganizations = selectedOrganizationsList.map((organization) => {
            if (organization.id === selectedOrganization.id) {
                return {
                    ...organization,
                    isManagementSelected: !organization.isManagementSelected,
                };
            }
            return organization;
        });

        this.setState({
            selectedOrganizationsList: updatedOrganizations,
        })
    };

    selectOrganizationsList = (organizations, update = false) => {
        const updateOrganizationsMembers = (mainOrganizationsList, newOrganizationsList) => {
          return mainOrganizationsList.map(mainOrganization => {
            const updatedOrganization = newOrganizationsList.find(organization => organization.id === mainOrganization.id);
            if (updatedOrganization) {
              return {
                ...mainOrganization,
                members: updatedOrganization.members
              };
            }
            return mainOrganization;
          });
        };

        if (update) {
            const updatedOrganizations = organizations.map((organization) => (
                {
                    ...organization,
                    isManagementSelected: false,
                }
            ))
            this.setState({
                selectedOrganizationsList: updatedOrganizations
            })
        } else {
            const organizationsByProjects = [...this.state.organizationsByProjects];

            this.setState({
                selectedOrganizationsList: organizations,
                organizationsByProjects: updateOrganizationsMembers(organizationsByProjects, organizations),
            })
        }
    }

    selectProjectsLists = (projects) => {
        this.setState({
            selectedProjects: projects
        })
    }

    cancelSubscription = async (organizationId) => {
        try {
            const {data} = await axiosApi.patch(`v2/billing/${organizationId}/subscription/`, {});
            if (!data.id) return;

            const organizationData = await this.getOneOrganization(data.id);

            if (organizationData.length === 0) return;
            const actualOrganization = organizationData[0];

            const updateOrganizationsPermissions = (organizationsList) => {
                return organizationsList.forEach(organization => {
                    if (organization.id === actualOrganization.id) {
                        organization.generation_allowed = actualOrganization.generation_allowed;
                        organization.notice_code = actualOrganization.notice_code;
                        organization.short_notice_deadline = actualOrganization.short_notice_deadline;
                    }
                });
            };

            const organizationsByProjects = [...this.state.organizationsByProjects];
            const selectedOrganizationsList = [...this.state.selectedOrganizationsList];

            updateOrganizationsPermissions(organizationsByProjects);
            updateOrganizationsPermissions(selectedOrganizationsList);

            this.setState({
                organizationsByProjects,
                selectedOrganizationsList,
            });

        } catch (error) {
            if (error.isAxiosError) {
                message.warning('Canceling of the subscription finished with error! Try again in a while.');
            } else {
                throw new Error(error);
            }
        }
    }

    switchSubscription = async (organizationId, planId) => {
        try {
            await axiosApi.put(`v2/billing/${organizationId}/subscription/`, {new_plan: planId});

            const monthlyReport = await this.getPaymentsData(organizationId);

            if (monthlyReport.length === 0) return;
            const actualMonthlyReport = monthlyReport[0];

            const updateOrganizationsPermissions = (organizationsList) => {
                return organizationsList.forEach(organization => {
                    if (organization.id === actualMonthlyReport.id) {
                        organization.monthly_report.plan = actualMonthlyReport.monthly_report.plan;
                    }
                });
            };

            const organizationsByProjects = [...this.state.organizationsByProjects];
            const selectedOrganizationsList = [...this.state.selectedOrganizationsList];

            updateOrganizationsPermissions(organizationsByProjects);
            updateOrganizationsPermissions(selectedOrganizationsList);

            this.setState({
                organizationsByProjects,
                selectedOrganizationsList,
            });

            message.success('Billing plan updated! New billing plan will commence on your next payment date.')

        } catch (error) {
            if (error.isAxiosError) {
                message.warning('Billing plan updating finished with error! Try again in a while.');
            } else {
                throw new Error(error);
            }
        }
    }

    getBillingPlan = async (setLoading, organizationId) => {
        try {
            if (setLoading) {
                setLoading(true)
            }

            const isRuRegion = getUserLocalStorage('ru_region');

            const data = [];

            if (!isRuRegion) {
              const response = await axiosApi.get('v2/billing/default-price-plans/');
              data.push(...response.data);
            }

            if (organizationId) {
                const customPlansData = await axiosApi.get(`v2/billing/price-plans/?id__in=${organizationId}`);
                const additionalPlans = customPlansData.data[0];
                if (additionalPlans?.custom_plans.length) {
                    data.push(...additionalPlans.custom_plans);
                }
            }

            return data;
        } catch (error) {
            if (error.isAxiosError) {
                return message.warning({
                    content: error.message,
                    duration: 3,
                });
            }
            throw new Error(error);
        } finally {
            if (setLoading) {
                setLoading(false)
            }
        }
    };


    getPaymentLink = async (organizationId, pricePlan) => {
        try {
            const {data} = await axiosApi.patch(`v2/billing/${organizationId}/payment-link/`, {price_plan: pricePlan});
            return data
        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    getLinkForAddingPaymentMethod = async (organizationId) => {
        try {
            const {data} = await axiosApi.get(`v2/billing/${organizationId}/payment-method/`);

            return data;
        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    changeDefaultPaymentMethod = async (organizationId, paymentMethodId, setLoading) => {
        try {
            setLoading(true);

            const {data} = await axiosApi.patch(`v2/billing/${organizationId}/default-payment-method/`, {payment_method: paymentMethodId});

            if (!data) return;

            const selectedOrganizationsList = [...this.state.selectedOrganizationsList];
            selectedOrganizationsList.forEach((organization) => {
                if (organization.id === organizationId) {
                    organization.payment_methods.forEach((method) => {
                        method.default = false;
                        if (method.id === data.id) {
                            method.default = data.default;
                        }
                    })
                }
            });

            this.setState({selectedOrganizationsList});
            message.success('Default payment method successfully changed');

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        } finally {
            setLoading(false);
        }
    };

    deletePaymentMethod = async (organizationId, paymentMethodId) => {
        try {
            await axiosApi.delete(`v2/billing/${organizationId}/default-payment-method/`, {data: {payment_method: paymentMethodId}});
        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: 'Payment method deleting finished with error',
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    updatingPaymentMethods = (organizationsList, newPaymentMethods) => {
        return organizationsList.forEach(organization => {
            if (organization.id === newPaymentMethods.id) {
                organization.payment_methods = newPaymentMethods.payment_methods;
            }
        });
    }

    onDeletingPaymentMethod = async (organizationId, paymentMethodId) => {
        await this.deletePaymentMethod(organizationId, paymentMethodId);
        const paymentData = await this.getPaymentsData(organizationId);
        if (!paymentData) return;

        const actualPaymentData = paymentData[0];

        const organizationsByProjects = [...this.state.organizationsByProjects];
        const selectedOrganizationsList = [...this.state.selectedOrganizationsList];

        this.updatingPaymentMethods(organizationsByProjects, actualPaymentData);
        this.updatingPaymentMethods(selectedOrganizationsList, actualPaymentData);

        this.setState({
            organizationsByProjects,
            selectedOrganizationsList,
        });
    }

    getPaymentsData = async (organizationId) => {
        try {
            const {data} = await axiosApi.get(`v2/billing/monthly-reports/${organizationId ? `?id__in=${organizationId}` : ''}`);
            return data;

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: `Something went wrong! ${error.message}`,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    createOrganization = async (requestBody) => {
        try {
            const {data} = await axiosApi.post('v2/accounts/organizations/', requestBody);
            return data;

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: `Something went wrong! ${error.message}`,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        }
    };

    updateOrganizationName = async (id, name, setLoading) => {
        try {
            setLoading(true);
            const {data} = await axiosApi.patch(`v2/accounts/organizations/${id}/`, {name});

            if (!data.name) return;

            const updateNameInArrays = (...arrays) => {
                arrays.forEach(array => {
                    array.forEach(obj => {
                        if (obj.id === id) {
                            obj.name = data.name;
                        }
                    });
                });
            };

            const selectedOrganizationsList = [...this.state.selectedOrganizationsList];
            const organizationsWithFilteredProjects = [...this.state.organizationsWithFilteredProjects];
            const organizations = [...this.state.organizations];
            const organizationsByProjects = [...this.state.organizationsByProjects];
            const selectedProjects = [...this.state.selectedProjects];

            updateNameInArrays(
                selectedOrganizationsList,
                organizationsWithFilteredProjects,
                organizations,
                organizationsByProjects,
                selectedProjects
            );

            this.setState({
                selectedOrganizationsList,
                organizationsWithFilteredProjects,
                organizations,
                organizationsByProjects,
                selectedProjects,
            }, () => {
                message.success(`Organization's name successfully changed to ${name}`);
            });

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: `Something went wrong! ${error.message}`,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        } finally {
            setLoading(false);
        }
    };


    getProductById = async (feedId, productId, setLoading) => {
        try {
            setLoading(true);
            const {data} = await axiosApi.get(`/feed/?feed_id=${feedId}&page_num=0&product_id=${productId}`);

            if (!data.products.length) {
                message.error({
                    content: 'Product with given ID is not fount',
                    duration: 3,
                });
                return;
            }
            return data.products[0];

        } catch (error) {
            if (error.isAxiosError) {
                message.warning({
                    content: error.message,
                    duration: 3,
                });
            } else {
                throw new Error(error);
            }
        } finally {
            setLoading(false);
        }
    };

    onAddingOrganizationClick = () => {
        if (this.state.isAddingNewOrganization) {
            message.info({
                content: 'You have already started adding a new organization, please complete it before adding another one',
                duration: 5
            });
        }
        this.setState({
            isAddingNewOrganization: true
        });
    };

    onAddingOrganizationFinish = () => {
        this.setState({
            isAddingNewOrganization: false
        });
    };

    onAddingProjectClick = () => {
        if (this.state.isAddingNewProject) {
            message.info({
                content: 'You have already started adding a new project, please complete it before adding another one',
                duration: 5
            });
        }
        this.setState({
            isAddingNewProject: true
        });
    };

    onAddingProjectFinish = () => {
        this.setState({
            isAddingNewProject: false
        });
    };


    handleSwitchMenuSelect = page => {
        this.setState({
            currentPage: page
        });
    };

    setOrganizationsLoading = (value) => {
        this.setState( {
            loadingOrganizations: value
        });
    };

    onCollapse = collapsed => {
        this.setState({collapsed});
    };

    componentDidMount() {

        this.checkUserAuthAndOrganizations()
            .then(() => {
                this.setState({loaded: true});
            });
    }

    state = {
        user: null,
        fonts: [],
        designFonts: [],
        organizations: [],
        organizationsByProjects: [],
        organizationsWithFilteredProjects: [],
        selectedOrganization: null,
        selectedOrganizationsList: [],
        selectedProjects: [],
        selectedProjectId: null,
        billingPlans: [],
        loadingBillingPlans: false,
        loadingOrganizations: false,
        loadingProjects: false,
        setOrganizationsLoading: this.setOrganizationsLoading,
        projects: [],
        loaded: false,
        error: undefined,
        isAddingNewOrganization: false,
        isAddingNewProject: false,
        setUserAuth: this.setUserAuth,
        logOut: this.logOut,
        logIn: this.logIn,
        register: this.register,
        getInvitationCode: this.getInvitationCode,
        finishRegistrationForInvitedUser: this.finishRegistrationForInvitedUser,
        confirmRegistration: this.confirmRegistration,
				resetPassword: this.resetPassword,
				setNewPassword: this.setNewPassword,
        fetchFeed: this.fetchFeed,
        fetchProject: this.fetchProject,
        fetchMapping: this.fetchMapping,
        fetchRule: this.fetchRule,
        fetchDesign: this.fetchDesign,
        fetchDesigns: this.fetchDesigns,
        handleSwitchMenuSelect: this.handleSwitchMenuSelect,
        deleteTemplate: this.deleteDesign,
        getFonts: this.getFonts,
        uploadFont: this.uploadFont,
        setDesignFont: this.setDesignFont,
        deleteFont: this.deleteFont,
        deleteFile: this.deleteFile,
        fetchFeedTypes: this.fetchFeedTypes,
        getOrganizations: this.getOrganizations,
        getOrganizationsWithFilteredProjects: this.getOrganizationsWithFilteredProjects,
        getOneProjectByOrganization: this.getOneProjectByOrganizationAndUpdateState,
        getProductById: this.getProductById,
        cancelSubscription: this.cancelSubscription,
        switchSubscription: this.switchSubscription,
        getBillingPlan: this.getBillingPlan,
        getPaymentLink: this.getPaymentLink,
        getLinkForAddingPaymentMethod: this.getLinkForAddingPaymentMethod,
        getPaymentsData: this.getPaymentsData,
        onDeletingPaymentMethod: this.onDeletingPaymentMethod,
        changeDefaultPaymentMethod: this.changeDefaultPaymentMethod,
        createOrganization: this.createOrganization,
        updateOrganizationName: this.updateOrganizationName,
        selectOrganization: this.selectOrganization,
        selectOrganizationsList: this.selectOrganizationsList,
        selectProjectsLists: this.selectProjectsLists,
        toggleIsManagementSelected: this.toggleIsManagementSelected,
        onAddingOrganizationClick: this.onAddingOrganizationClick,
        onAddingOrganizationFinish: this.onAddingOrganizationFinish,
        onAddingProjectClick: this.onAddingProjectClick,
        onAddingProjectFinish: this.onAddingProjectFinish,
        clearOrganizationsWithFilteredProjects: this.clearOrganizationsWithFilteredProjects,
        collapsed: false,
        currentPage: "index",
    };

    render() {
      const GlobalStyle2 = createGlobalStyle`
            body {
                ${props => props.fonts.map(font => `
                  @font-face {
                    font-family: '${font.name === 'Inter-Bold.ttf' ? font.name.replace(/\.ttf$/, '') : font.family}';
                    src: url(${font.font.replace(' ', '_')}) format('${font.font_format}');
                    font-display: swap;
                }
              `).join('')}
            }`;

      return this.state.loaded && (
            <Router>
                {this.state.fonts && <GlobalStyle2 fonts={this.state.fonts || []}/>}
                <ConfigProvider
                    theme={{
                        token: {
                            fontFamily: "Inter",
                            colorPrimary: COLORS.mainViolet
                        }
                    }}
                >
                    <SocketInitializer organizations={this.state.organizations} selectedProject={this.state.selectedProjectId}>
                        <Layout style={{height: '100vh', background: 'transparent'}}>
                            {
                                this.state.user ?
                                    <>
                                        <Space direction={"vertical"} style={{width: "100%"}}>
                                            <Content style={{margin: 0, height: '100vh'}}>
                                                <Switch>

                                                    <Route
                                                        exact path={"/register"}
                                                        render={() => <Register backend={this.state}/>}
                                                    />
                                                    <Route exact path="/">
                                                        <Redirect to='/admin'/>
                                                    </Route>
                                                    <Route
                                                        path="/project/:project_id/design/new"
                                                        render={(props) => <GraphicEditor {...props} backend={this.state}/>}
                                                    />
                                                    <Route
                                                        path="/project/:project_id/design/:design_id"
                                                        render={(props) => <GraphicEditor {...props} backend={this.state}/>}
                                                    />

                                                    <Route
                                                        exact path="/project/:project_id/rule/new"
                                                        render={() => <NodesApp backend={this.state}/>}
                                                    />
                                                    <Route
                                                        exact path="/project/:project_id/rule/:rule_id"
                                                        render={() => <NodesApp backend={this.state}/>}
                                                    />

                                                    <Route
                                                        path="/project/:project_id/mapping/new"
                                                        render={(props) => <MappingPage {...props} backend={this.state}/>}
                                                    />
                                                    <Route
                                                        path="/project/:project_id/mapping/:mapping_id"
                                                        render={(props) => <MappingPage {...props} backend={this.state}/>}
                                                    />

                                                    <Route path="/payment/:organizationId/" render={() =>
                                                        <ProtectedRoute
                                                            isAllowed={!this.state.user.isAllNotAdmin}
                                                            pathRedirect='/projects-new'
                                                        >
                                                            <SuccessPayment/>
                                                        </ProtectedRoute>
                                                    }/>

                                                    <Route path="/payment-method/:organizationId/" render={() =>
                                                        <ProtectedRoute
                                                            isAllowed={!this.state.user.isAllNotAdmin}
                                                            pathRedirect='/projects-new'
                                                        >
                                                            <SuccessPayment/>
                                                        </ProtectedRoute>
                                                    }/>
                                                    <Route
                                                        path="/admin/:organizationId"
                                                        render={() =>
                                                            <ProtectedRoute
                                                                isAllowed={!this.state.user.isAllNotAdmin}
                                                                pathRedirect='/projects-new'
                                                            >
                                                                <AppSidebar
                                                                    organizationsByProjects={this.state.organizationsByProjects}
                                                                    organizations={this.state.organizations}
                                                                    organizationsWithFilteredProjects={this.state.organizationsWithFilteredProjects}
                                                                    loadingOrganizations={this.state.loadingOrganizations}
                                                                    setOrganizationsLoading={this.state.setOrganizationsLoading}
                                                                    selectedOrganization={this.state.selectedOrganization}
                                                                    selectOrganizationsList={this.state.selectOrganizationsList}
                                                                    selectedOrganizationsList={this.state.selectedOrganizationsList}
                                                                    selectProjectsLists={this.state.selectProjectsLists}
                                                                    user={this.state.user}
                                                                    onAddingOrganizationClick={this.state.onAddingOrganizationClick}
                                                                    onAddingProjectClick={this.state.onAddingProjectClick}
                                                                    logOut={this.state.logOut}
                                                                >
                                                                    <AdminManagementPage
                                                                        organizationsByProjects={this.state.organizationsByProjects}
                                                                        getOrganizations={this.state.getOrganizations}
                                                                        selectedOrganizationsList={this.state.selectedOrganizationsList}
                                                                        loadingOrganizations={this.state.loadingOrganizations}
                                                                        selectOrganizationsList={this.state.selectOrganizationsList}
                                                                        totalOrganizations={this.state.organizations}
                                                                        cancelSubscription={this.state.cancelSubscription}
                                                                        switchSubscription={this.state.switchSubscription}
                                                                        defaultBillingPlans={this.state.billingPlans}
                                                                        getBillingPlan={this.state.getBillingPlan}
                                                                        getPaymentLink={this.state.getPaymentLink}
                                                                        getLinkForPaymentMethod={this.state.getLinkForAddingPaymentMethod}
                                                                        getPaymentsData={this.state.getPaymentsData}
                                                                        changeDefaultPaymentMethod={this.state.changeDefaultPaymentMethod}
                                                                        onDeletingPaymentMethod={this.state.onDeletingPaymentMethod}
                                                                        createOrganization={this.state.createOrganization}
                                                                        updateOrganizationName={this.state.updateOrganizationName}
                                                                        isAddingNewOrganization={this.state.isAddingNewOrganization}
                                                                        onAddingOrganizationFinish={this.state.onAddingOrganizationFinish}
                                                                        clearProjectsList={this.state.clearOrganizationsWithFilteredProjects}
                                                                        toggleIsManagementSelected={this.state.toggleIsManagementSelected}
                                                                    />
                                                                </AppSidebar>
                                                            </ProtectedRoute>
                                                        }
                                                    />

                                                    <Route
                                                        path="/admin"
                                                        render={() =>
                                                            <ProtectedRoute
                                                                isAllowed={!this.state.user.isAllNotAdmin}
                                                                pathRedirect='/projects-new'
                                                            >
                                                                <AppSidebar
                                                                    organizationsByProjects={this.state.organizationsByProjects}
                                                                    organizations={this.state.organizations}
                                                                    organizationsWithFilteredProjects={this.state.organizationsWithFilteredProjects}
                                                                    loadingOrganizations={this.state.loadingOrganizations}
                                                                    setOrganizationsLoading={this.state.setOrganizationsLoading}
                                                                    selectedOrganization={this.state.selectedOrganization}
                                                                    selectOrganizationsList={this.state.selectOrganizationsList}
                                                                    selectedOrganizationsList={this.state.selectedOrganizationsList}
                                                                    selectProjectsLists={this.state.selectProjectsLists}
                                                                    user={this.state.user}
                                                                    onAddingOrganizationClick={this.state.onAddingOrganizationClick}
                                                                    onAddingProjectClick={this.state.onAddingProjectClick}
                                                                    logOut={this.state.logOut}
                                                                >
                                                                    <AdminManagementPage
                                                                        organizationsByProjects={this.state.organizationsByProjects}
                                                                        getOrganizations={this.state.getOrganizations}
                                                                        selectedOrganizationsList={this.state.selectedOrganizationsList}
                                                                        loadingOrganizations={this.state.loadingOrganizations}
                                                                        selectOrganizationsList={this.state.selectOrganizationsList}
                                                                        totalOrganizations={this.state.organizations}
                                                                        cancelSubscription={this.state.cancelSubscription}
                                                                        switchSubscription={this.state.switchSubscription}
                                                                        defaultBillingPlans={this.state.billingPlans}
                                                                        getBillingPlan={this.state.getBillingPlan}
                                                                        getPaymentLink={this.state.getPaymentLink}
                                                                        getLinkForPaymentMethod={this.state.getLinkForAddingPaymentMethod}
                                                                        changeDefaultPaymentMethod={this.state.changeDefaultPaymentMethod}
                                                                        onDeletingPaymentMethod={this.state.onDeletingPaymentMethod}
                                                                        getPaymentsData={this.state.getPaymentsData}
                                                                        createOrganization={this.state.createOrganization}
                                                                        updateOrganizationName={this.state.updateOrganizationName}
                                                                        isAddingNewOrganization={this.state.isAddingNewOrganization}
                                                                        onAddingOrganizationFinish={this.state.onAddingOrganizationFinish}
                                                                        clearProjectsList={this.state.clearOrganizationsWithFilteredProjects}
                                                                        toggleIsManagementSelected={this.state.toggleIsManagementSelected}
                                                                    />
                                                                </AppSidebar>
                                                            </ProtectedRoute>
                                                        }
                                                    />

                                                <Route
                                                    path="/projects-new/:organizationId"
                                                    render={() =>
                                                        <AppSidebar
                                                            paymentsData={this.state.paymentsData}
                                                            organizationsByProjects={this.state.organizationsByProjects}
                                                            organizations={this.state.organizations}
                                                            organizationsWithFilteredProjects={this.state.organizationsWithFilteredProjects}
                                                            loadingOrganizations={this.state.loadingOrganizations}
                                                            setOrganizationsLoading={this.state.setOrganizationsLoading}
                                                            selectedOrganization={this.state.selectedOrganization}
                                                            selectOrganizationsList={this.state.selectOrganizationsList}
                                                            selectedOrganizationsList={this.state.selectedOrganizationsList}
                                                            selectProjectsLists={this.state.selectProjectsLists}
                                                            user={this.state.user}
                                                            onAddingOrganizationClick={this.state.onAddingOrganizationClick}
                                                            onAddingProjectClick={this.state.onAddingProjectClick}
                                                            logOut={this.state.logOut}
                                                        >
                                                            <ProjectsPage
                                                                getOrganizations={this.state.getOrganizations}
                                                                getProjectsByOrganization={this.state.getOrganizationsWithFilteredProjects}
                                                                organizationsWithFilteredProjects={this.state.organizationsWithFilteredProjects}
                                                                getOneProjectByOrganization={this.state.getOneProjectByOrganization}
                                                                updateOrganizationName={this.state.updateOrganizationName}
                                                                selectedProjectsList={this.state.selectedProjects}
                                                                totalProjects={this.state.organizationsWithFilteredProjects}
                                                                isAddingNewProject={this.state.isAddingNewProject}
                                                                onAddingProjectFinish={this.state.onAddingProjectFinish}
                                                                loading={this.state.loadingOrganizations}
                                                                updateSelectedProjectsList={this.state.selectProjectsLists}
                                                            />
                                                        </AppSidebar>
                                                    }
                                                />
                                                <Route
                                                    path="/projects-new"
                                                    render={() =>
                                                        <AppSidebar
                                                            paymentsData={this.state.paymentsData}
                                                            organizationsByProjects={this.state.organizationsByProjects}
                                                            organizations={this.state.organizations}
                                                            organizationsWithFilteredProjects={this.state.organizationsWithFilteredProjects}
                                                            loadingOrganizations={this.state.loadingOrganizations}
                                                            setOrganizationsLoading={this.state.setOrganizationsLoading}
                                                            selectedOrganization={this.state.selectedOrganization}
                                                            selectOrganizationsList={this.state.selectOrganizationsList}
                                                            selectedOrganizationsList={this.state.selectedOrganizationsList}
                                                            selectProjectsLists={this.state.selectProjectsLists}
                                                            user={this.state.user}
                                                            onAddingOrganizationClick={this.state.onAddingOrganizationClick}
                                                            onAddingProjectClick={this.state.onAddingProjectClick}
                                                            logOut={this.state.logOut}
                                                        >
                                                            <ProjectsPage
                                                                getOrganizations={this.state.getOrganizations}
                                                                getProjectsByOrganization={this.state.getOrganizationsWithFilteredProjects}
                                                                organizationsWithFilteredProjects={this.state.organizationsWithFilteredProjects}
                                                                getOneProjectByOrganization={this.state.getOneProjectByOrganization}
                                                                updateOrganizationName={this.state.updateOrganizationName}
                                                                selectedProjectsList={this.state.selectedProjects}
                                                                totalProjects={this.state.organizationsWithFilteredProjects}
                                                                isAddingNewProject={this.state.isAddingNewProject}
                                                                onAddingProjectFinish={this.state.onAddingProjectFinish}
                                                                loading={this.state.loadingOrganizations}
                                                                updateSelectedProjectsList={this.state.selectProjectsLists}
                                                            />
                                                        </AppSidebar>
                                                    }
                                                />
                                                <Route component={NotFound}/>
                                            </Switch>
                                        </Content>
                                    </Space>
                                </> :
                                <Switch>
                                    <Route
                                        path={"/register"}
                                        render={() => <Register backend={this.state}/>}
                                    />
                                    <Route
                                        path="/login"
                                        render={props => <Login {...props} backend={this.state}/>}
                                    />
                                    <Route
                                        path="/accounts/register"
                                        render={() =>
                                            <RegisterByInvitation
                                                getInvitationCode={this.state.getInvitationCode}
                                                finishRegistration={this.state.finishRegistrationForInvitedUser}
                                            />
                                        }
                                    />
                                  <Route
                                      path="/confirm-registration"
                                      render={() =>
                                          <RegistrationConfirmation
                                              confirmRegistration={this.state.confirmRegistration}
                                          />
                                      }
                                  />
																	<Route
			                                path="/request-reset-password"
			                                render={() => <RequestResetPassword onReset={this.state.resetPassword}/>}
	                                />
	                                <Route
			                                path="/reset-password"
			                                render={() => <ResetPassword setNewPassword={this.state.setNewPassword}/>}
	                                />

                                        <Redirect to={"/login"}/>
                                    </Switch>
                            }
                        </Layout>
                    </SocketInitializer>
                </ConfigProvider>
            </Router>
        )
    }
}
